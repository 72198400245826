<template>
  <base-content-layout
    :visibleDataTable="visibleDataTable"
    :error="currentError"
    :loading="isLoading"
    :record="record"
    v-on:alertVisibilityChanged="onAlertVisibilityChanged"
    v-on:expandedPreview="onExpandedPreview"
  >
    <!-- module details (inbox-details) -->
    <template v-slot:module-details>
      <inbox-details :task="task"></inbox-details>
    </template>

    <!-- record details -->
    <template v-slot:record-details>
      <inbox-record-details></inbox-record-details>
    </template>

    <!-- Sub record list -->
    <template v-slot:record-list v-if="visibleSubRecords">
      <inbox-documents v-show="visibleSubRecords"></inbox-documents>
    </template>

    <!-- record history -->
    <template v-slot:record-history v-if="visibleFileVersions">
      <inbox-file-versions v-show="visibleFileVersions"></inbox-file-versions>
    </template>

    <!-- Preview -->
    <template v-slot:record-preview v-if="visiblePreviewTab">
      <!--
        TODO: enable DocumentViewerPreview when conflict of
        preview/DocumentEditorDialog is resolved
      -->
      <!-- Preview record-image-pdf-file ONLY when expanded Preview -->
      <document-viewer
        v-if="visibleDocumentViewerPreview"
        :record-id="recordId"
        :record-version="previewRecordVersion"
        :preview-only="true"
        :is-open-document="expandedPreview"
      ></document-viewer>

      <!-- Preview current record's file(s) -->
      <document-preview
        v-if="visibleDocumentPreview"
        :record="record"
        :open="expandedPreview"
      ></document-preview>
    </template>

    <!-- e.g. How to use Snackbar if/when needed -->
    <template v-slot:snackbar>
      <snackbar
        v-model="snackbarVisible"
        @close="closeSnackbar"
        :color="snackbarColor"
        >{{ computedSnackbarText }}</snackbar
      >
    </template>
  </base-content-layout>
</template>

<script>
import { inboxContentMixin } from "@/mixins/inbox/content/inboxContentMixin";

export default {
  name: "InboxContent",
  mixins: [inboxContentMixin],
  data() {
    return {
      panelDocuments: undefined,
      panelHistory: undefined
    };
  },
  components: {
    InboxDetails: () => import("@/views/inbox/InboxDetails"),
    InboxRecordDetails: () => import("@/views/inbox/InboxRecordDetails"),
    InboxDocuments: () => import("@/views/inbox/InboxDocuments"),
    InboxFileVersions: () => import("@/views/inbox/InboxFileVersions"),
    DocumentPreview: () => import("@/views/document/DocumentPreview"),
    DocumentViewer: () => import("@/views/document/DocumentViewer")
  }
};
</script>
