// vuex
import { createNamespacedHelpers } from "vuex";
const { mapState, mapGetters, mapActions } = createNamespacedHelpers("inbox");

// mixins
import { moduleContentRecordMixin } from "@/mixins/shared/content/moduleContentRecordMixin";

/**
 * Inbox Content Mixin
 */
export const inboxContentMixin = {
  mixins: [moduleContentRecordMixin],
  computed: {
    /**
     * Overwrite moduleContentRecordMixin.record()
     */
    ...mapState({
      /**
       * Get current selected Inbox Module's record
       * Overwrite moduleContentRecordMixin.record()
       * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[] }}
       */
      record: "record",

      /**
       * Get current selected Inbox's Task
       * @return {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee:string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole:string, canReassign:boolean, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone:boolean, isOverdue:boolean, notifyOnEscalation:boolean, priority:number, selfReleaseDisabled:boolean, workflowDefinitionId:number, notes: {id:number, taskId:number, text:string, userId:number, userName:string, created:string}[]}}
       */
      task: "task"
    }),

    ...mapGetters({
      /**
       * Get selected Inbox Module's Item Id (task id)
       * Overwrite moduleContentMixin.selectedModuleItemId()
       * @return {number}
       */
      selectedModuleItemId: "selectedModuleItemId",

      /**
       * Get selected Inbox Module's Item Name (task name)
       * Overwrite moduleContentMixin.selectedModuleItemName()
       * @return {string}
       */
      selectedModuleItemName: "selectedModuleItemName"
    })
  },
  methods: {
    ...mapActions({
      /**
       * set current Inbox Module's Record
       * Overwrite moduleContentRecordMixin.setRecord()
       */
      setRecord: "setRecord",

      /**
       * set current Inbox's Task
       * Overwrite moduleContentMixin.setModuleItem()
       */
      setModuleItem: "setTask"
    })
  },
  watch: {
    /**
     * watch current selected task
     * @param {{taskId: number, name: string, typeId: number, templateId: number, projectTemplateName: string, projectId:number, projectName:string, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, actorId:number, assignee:string, assignmentMethod:string, assignmentMethodType:number, assignmentMethodTypeText:string, assignmentNotificationType:number, assignmentNotificationTypeText:string, assignmentRole:string, canReassign:boolean, comment:string, dueDate:string, duration:string, isDue:boolean, isMilestone:boolean, isOverdue:boolean, notifyOnEscalation:boolean, priority:number, selfReleaseDisabled:boolean, workflowDefinitionId:number, notes: {id:number, taskId:number, text:string, userId:number, userName:string, created:string}[]}} newTask
     * @return {Promise<void>}
     */
    async task(newTask) {
      try {
        this.clearError();
        const recordId = newTask?.recordId ?? -1;
        await this.setRecord(recordId);
      } catch (e) {
        this.handleError(e);
      }
    }
  }
};
